


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';

@Component({
	components: {
		Layout,
		Modal,
	},
})
export default class TravelPreviewModal extends Vue {
	@Prop({ required: true }) readonly visible!: boolean;
	@Prop({ required: true }) readonly id!: string;

	boarding = { odometro: '', data: '', imagem: '' };
	landing = { odometro: '', data: '', imagem: '' };
	travelBegin = { odometro: '', data: '', imagem: '' };

	@Watch('visible')
	onVisibleChanged(newVal: boolean) {
		if (newVal) {
			this.clearValues();
			this.getTravelDetail();
		}
	}

	closeModal() {
		this.$emit('close');
	}

	async getTravelDetail() {
		try {
			const data = await this.$store.dispatch('TRANSIT_TRAVEL_DETACHED_DETAIL', this.id);
			this.boarding = data.embarque;
			this.landing = data.desembarque;
			this.travelBegin = data.inicioViagem;
		} catch (error) {
			console.error('Erro ao obter detalhes da viagem:', error);
		}
	}

	clearValues() {
		this.boarding = { odometro: '', data: '', imagem: '' };
		this.landing = { odometro: '', data: '', imagem: '' };
		this.travelBegin = { odometro: '', data: '', imagem: '' };
	}

	openImage(value: { odometro: string; data: string; imagem: string }) {
		if (value.imagem) {
			const newTab = window.open();
			if (newTab) {
				newTab.document.body.innerHTML = `<img src="${value.imagem}" style="width:100vw; height:100vh; object-fit: contain;" />`;
			}
		}
	}
}
