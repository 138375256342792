









































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Filters from './organisms/Filter/Filter.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import TravelDetachedModal from './organisms/Modals/Travels/Travels.vue';
import TravelPreviewModal from './organisms/Modals/TravelPreviewModal/TravelPreviewModal.vue';

import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { saveXLSX } from '@/helpers/ExportXLSX';
import { formatDateISO, formatDate, formatHour } from '@/shared/util/formatDate';

import {
	TRANSIT_GET_DOWNLOAD_IMAGE,
	TRANSIT_TRAVEL_DETACHED_FORM_REQUEST,
	SELECT_TRANSIT_TRAVEL_DETACHED_FORM,
	SELECT_TRANSIT_DRIVER_DETACHED_FORM,
} from '@/store/TransitFacility/travels/actions';

@Component({
	components: {
		Layout,
		Header,
		Filters,
		Table,
		Pagination,
		TravelDetachedModal,
		TravelPreviewModal,
	},
})
export default class TransitModuleTravel extends Vue {
	/* Data */
	private availableFilters: any = AvailableFilters;

	private isLoaded: boolean = true;

	private showPreviewModal: boolean = false;
	private selectedIdDetail: string = '';

	private busy: any = {
		xlsx: false,
		image: false,
	};

	private params: any = {
		page: 1,
		size: 16,
		dataInicio: null,
		viagemVinculada: 'false',
	};

	private fixedHeader: any = [
		{ key: 'customId', label: 'Id', thClass: 'dark-header' },
		{ key: 'motorista', label: 'Motorista', thClass: 'dark-header' },
		{ key: 'dataInicio', label: 'Data de Início de Viagem', thClass: 'dark-header' },
		{ key: 'horaInicio', label: 'Hora', thClass: 'dark-header' },
		{
			key: 'actions',
			label: 'Ações',
			thClass: 'brown-header',
			tdClass: 'td-action',
		},
		{ key: 'details', label: 'Detalhes', thClass: 'brown-header', tdClass: 'td-action' },
	];

	private header: any = [];

	private columns: Array<any> = [];

	private rows: any = [];

	private totalPages: number = 0;

	/* Lifecycle */
	created() {
		this.getDetachedForms(this.params);
	}

	submitFilter(filterDefinition: any): void {
		Object.keys(filterDefinition).forEach(index => {
			const numberIndex = +index;
			switch (numberIndex) {
				case this.availableFilters.Date:
					this.params.dataInicio = formatDateISO(filterDefinition[numberIndex].startDateSelected);
					this.params.dataFim = formatDateISO(filterDefinition[numberIndex].endDateSelected);
					break;
				case this.availableFilters.DriverDetachedForm:
					this.params.idMotorista = filterDefinition[numberIndex].driverSelected?.value || null;
					break;
			}
		});
		this.params.page = 1;
		this.getDetachedForms(this.params);
	}

	getDetachedForms(params: any) {
		this.isLoaded = false;
		this.$store
			.dispatch(TRANSIT_TRAVEL_DETACHED_FORM_REQUEST, params)
			.then(({ totalCount, results }) => {
				if (results.length == 0) return this.$toast.error('Nenhuma viagem encontrada');

				this.header = this.fixedHeader;
				this.totalPages = totalCount;
				this.rows = results.map(i => {
					return {
						...i,
						customId: i.customId,
						idViagemAvulsa: i.idViagemAvulsa,
						idMotorista: i.idMotorista,
						motorista: i.motorista,
						dataInicio: formatDate(i.dataInicio),
						horaInicio: formatHour(i.dataInicio),
						viagemVinculada: i.viagemVinculada,
						actions: {
							id: i.idViagemAvulsa,
							idMotorista: i.idMotorista,
							buttons: ['link'],
						},
						details: {
							buttons: ['detail'],
							id: i.customId,
						},
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.isLoaded = true;
			});
	}

	message(message, variant) {
		this.$nextTick(() => {
			this.$toast.open({
				message,
				type: variant,
			});
		});
	}

	changePage(page) {
		this.params.page = page;
		this.getDetachedForms(this.params);
	}

	changeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getDetachedForms(this.params);
	}

	exportXLS() {
		const params = { ...this.params };
		params.size = this.totalPages;
		this.busy.xlsx = true;
		let body = [];
		const header = this.header.map(item => {
			return { label: item?.label || item?.key, value: item?.key };
		});
		header.pop();
		this.$store
			.dispatch(TRANSIT_TRAVEL_DETACHED_FORM_REQUEST, params)
			.then(({ results }) => {
				if (results.length == 0) {
					return;
				}
				body = results.map(i => {
					return {
						...i,
						customId: i.customId,
						idViagemAvulsa: i.idViagemAvulsa,
						idMotorista: i.idMotorista,
						motorista: i.motorista,
						dataInicio: formatDate(i.dataInicio),
						horaInicio: formatHour(i.dataInicio),
						viagemVinculada: i.viagemVinculada,
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.xlsx = false;
				saveXLSX(header, body, 'tabela', 'Lista-viagens-avulsas');
			});
	}

	downloadImage(params) {
		this.busy.image = true;
		this.$store
			.dispatch(TRANSIT_GET_DOWNLOAD_IMAGE, params)
			.then(() => {
				this.$toast.success('Download realizado com sucesso!');
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.image = false;
			});
	}

	openTravels(viagem) {
		this.$store.dispatch(SELECT_TRANSIT_DRIVER_DETACHED_FORM, viagem.idMotorista);
		this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETACHED_FORM, viagem.id);
	}

	onModalClose(): void {
		this.params.page = 1;
		this.getDetachedForms(this.params);
	}

	handlePreviewModal(data): void {
		if (!this.showPreviewModal) {
			this.selectedIdDetail = data.id;
			this.showPreviewModal = !this.showPreviewModal;
			return;
		}
		this.selectedIdDetail = '';
		this.showPreviewModal = !this.showPreviewModal;
		return;
	}
}
