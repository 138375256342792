/* Imports */
import TravelState from '@/models/TransitFacility/travel-state/travel-state.interface';
import api from '@/shared/util/api';
import { CONSTANTS } from '@/constants/constants';
const { http } = api.getInstance();

/* Actions */
import {
	TRANSIT_LOAD_TRAVEL_REQUEST,
	TRANSIT_LOAD_TRAVEL_SUCCESS,
	TRANSIT_LOAD_TRAVEL_FAILED,
	TRANSIT_TRAVEL_DETAIL_REQUEST,
	TRANSIT_TRAVEL_DETAIL_SUCCESS,
	TRANSIT_TRAVEL_DETAIL_FAILED,
	TRANSIT_GET_COLUMNS,
	TRANSIT_GET_DOWNLOAD_FILE,
	TRANSIT_GET_DOWNLOAD_IMAGE,
	TRANSIT_GET_ACTIVITY_HISTORY,
	TRANSIT_GET_RESUME,
	TRANSIT_GET_KM_OCCURRENCE,
	TRANSIT_GET_ANIMAL_OCCURRENCE,
	TRANSIT_GET_TRUCK_OCCURRENCE,
	TRANSIT_GET_MAP_PATH,
	TRANSIT_GET_ACTUAL_LOCALIZATION,
	TRANSIT_GET_PARTNERS,
	TRANSIT_GET_PARTNER_REQUEST,
	TRANSIT_GET_PARTNER_SUCCESS,
	TRANSIT_GET_PARTNER_FAILED,
	TRANSIT_GET_TRUCKS,
	TRANSIT_GET_CARTS,
	TRANSIT_TRAVEL_GET_DRIVERS,
	TRANSIT_PUT_TRAVEL_DETAIL,
	TRANSIT_PUT_COLUMNS,
	TRANSIT_UPLOAD_FILE,
	TRANSIT_POST_ACTIVITY_HISTORY,
	TRANSIT_POST_KM_OBSERVATION,
	TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION,
	TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION,
	TRANSIT_POST_ANIMAL_LANDING_OBSERVATION,
	TRANSIT_POST_TRUCK_OBSERVATION,
	TRANSIT_POST_PROCESS_CTE,
	SELECT_TRANSIT_TRAVEL_DETAIL,
	SELECT_TRANSIT_HISTORICAL,
	SELECT_TRANSIT_MAP,
	SELECT_TRANSIT_OCCURRENCE,
	SELECT_TRANSIT_RESUME,
	TRANSIT_TRAVEL_DETACHED_FORM_REQUEST,
	SELECT_TRANSIT_TRAVEL_DETACHED_FORM,
	TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST,
	SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP,
	TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM,
	SELECT_TRANSIT_DRIVER_DETACHED_FORM,
	LOAD_TRAVEL_LOGGPS_REQUEST,
	LOAD_TRAVEL_LOGGPS_SUCCESS,
	LOAD_TRAVEL_LOGGPS_FAILED,
	HANDLE_OUTSYSTEM_STATUS,
	TRANSIT_TRAVEL_DETACHED_DETAIL,
} from './actions';

/* Initial AuthState object */
const state: TravelState = {
	loadTravelStatus: '',
	loadTravelDetailStatus: '',
	loadPartnerStatus: '',
	selectedTravel: null,
	selectedMap: null,
	selectedOccurence: null,
	selectedResume: null,
	selectedHistorical: null,
	selectedTravelDetached: null,
	selectedTravelFromDetachedForm: null,
	selectedDriverDetachedForm: null,
};

/* Vuex Auth Getters */
const getters = {
	isTransitTravelLoading: (state: TravelState): string => state.loadTravelStatus,
	isTransitTravelDetailLoading: (state: TravelState): boolean =>
		state.loadTravelDetailStatus === 'loading',
	isTransitPartnerLoading: (state: TravelState): boolean => state.loadPartnerStatus === 'loading',
	hasSelectedTransitTravel: (state: TravelState): boolean => state.selectedTravel !== null,
	getSelectedTransitTravel: state => state.selectedTravel,
	hasSelectedTransitMap: (state: TravelState): boolean => state.selectedMap !== null,
	getSelectedTransitMap: state => state.selectedMap,
	hasSelectedTransitOccurence: (state: TravelState): boolean => state.selectedOccurence !== null,
	getSelectedTransitOccurence: (state: TravelState) => state.selectedOccurence,
	hasSelectedTransitResume: (state: TravelState): boolean => state.selectedResume !== null,
	getSelectedTransitResume: (state: TravelState) => state.selectedResume,
	hasSelectedHistorical: (state: TravelState): boolean => state.selectedHistorical !== null,
	getSelectedHistorical: (state: TravelState) => state.selectedHistorical,
	hasSelectedTransitTravelDetachedForm: (state: TravelState): boolean =>
		state.selectedTravelDetached !== null,
	getSelectedTransitTravelDetachedForm: state => state.selectedTravelDetached,
	hasSelectedTransitTravelFromDetachedForm: (state: TravelState): boolean =>
		state.selectedTravelFromDetachedForm !== null,
	getSelectedTransitTravelFromDetachedForm: state => state.selectedTravelFromDetachedForm,
	hasSelectedDriverDetachedForm: (state: TravelState): boolean =>
		state.selectedDriverDetachedForm !== null,
	getSelectedDriverDetachedForm: state => state.selectedDriverDetachedForm,
};

/* Vuex Auth Mutations */
const mutations = {
	[TRANSIT_LOAD_TRAVEL_REQUEST]: (state: TravelState) => {
		state.loadTravelStatus = 'loading';
	},
	[TRANSIT_LOAD_TRAVEL_SUCCESS]: (state: TravelState) => {
		state.loadTravelStatus = 'success';
	},
	[TRANSIT_LOAD_TRAVEL_FAILED]: (state: TravelState) => {
		state.loadTravelStatus = 'error';
	},
	[TRANSIT_TRAVEL_DETAIL_REQUEST]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'loading';
	},
	[TRANSIT_TRAVEL_DETAIL_SUCCESS]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'success';
	},
	[TRANSIT_TRAVEL_DETAIL_FAILED]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'error';
	},
	[TRANSIT_GET_PARTNER_REQUEST]: (state: TravelState) => {
		state.loadPartnerStatus = 'loading';
	},
	[TRANSIT_GET_PARTNER_SUCCESS]: (state: TravelState) => {
		state.loadPartnerStatus = 'success';
	},
	[TRANSIT_GET_PARTNER_FAILED]: (state: TravelState) => {
		state.loadPartnerStatus = 'error';
	},
	[SELECT_TRANSIT_TRAVEL_DETAIL]: (state, travel) => {
		state.selectedTravel = travel;
	},
	[SELECT_TRANSIT_TRAVEL_DETACHED_FORM]: (state, travel) => {
		state.selectedTravelDetached = travel;
	},
	[SELECT_TRANSIT_MAP]: (state, map) => {
		state.selectedMap = map;
	},
	[SELECT_TRANSIT_OCCURRENCE]: (state, occurence) => {
		state.selectedOccurence = occurence;
	},
	[SELECT_TRANSIT_RESUME]: (state, resume) => {
		state.selectedResume = resume;
	},
	[SELECT_TRANSIT_HISTORICAL]: (state, historic) => {
		state.selectedHistorical = historic;
	},
	[SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP]: (state, travel) => {
		state.selectedTravelFromDetachedForm = travel;
	},
	[SELECT_TRANSIT_DRIVER_DETACHED_FORM]: (state, driver) => {
		state.selectedDriverDetachedForm = driver;
	},
};

/* Vuex Auth Actions */
const actions = {
	[TRANSIT_LOAD_TRAVEL_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach(data => {
				if (requestData[data] && data !== 'page' && data !== 'size' && data !== 'colunas') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			if (requestData?.colunas && requestData.colunas.length > 0) {
				requestData.colunas.forEach(coluna => {
					queryParams.push(`colunas=${coluna}`);
				});
			}
			commit(TRANSIT_LOAD_TRAVEL_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsviagem?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_LOAD_TRAVEL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_LOAD_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[LOAD_TRAVEL_LOGGPS_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach(data => {
				if (requestData[data] && data !== 'page' && data !== 'size') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});

			commit(LOAD_TRAVEL_LOGGPS_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbslog-gps?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(LOAD_TRAVEL_LOGGPS_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(LOAD_TRAVEL_LOGGPS_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DETACHED_FORM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach(data => {
				if (requestData[data] && data !== 'page' && data !== 'size' && data !== 'colunas') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			if (requestData?.colunas && requestData.colunas.length > 0) {
				requestData.colunas.forEach(coluna => {
					queryParams.push(`colunas=${coluna}`);
				});
			}
			commit(TRANSIT_LOAD_TRAVEL_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsviagem-avulsa?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_LOAD_TRAVEL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_LOAD_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach(data => {
				if (requestData[data] && data !== 'page' && data !== 'size' && data !== 'colunas') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			if (requestData?.colunas && requestData.colunas.length > 0) {
				requestData.colunas.forEach(coluna => {
					queryParams.push(`colunas=${coluna}`);
				});
			}
			commit(TRANSIT_LOAD_TRAVEL_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsviagem-avulsa/lista-viagem?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_LOAD_TRAVEL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_LOAD_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem-avulsa/vincular-viagem`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_GET_DRIVERS]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) queryParams.push(`name=${requestData.search}`);
			http({
				method: 'get',
				url: `/transportadora/jbsparceiro/motorista?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_PARTNERS]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) queryParams.push(`name=${requestData.search}`);
			http({
				method: 'get',
				url: `/transportadora/jbsparceiro/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_TRUCKS]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) queryParams.push(`placa=${requestData.search}`);
			http({
				method: 'get',
				url: `/transportadora/jbscadastro/caminhao/placa?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_CARTS]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`placa=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/transportadora/jbscadastro/carreta/placa?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_PARTNER_REQUEST]: ({ commit }: any, idParceiro: number) => {
		return new Promise<void>((resolve, reject) => {
			commit(TRANSIT_GET_PARTNER_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsparceiro/detalhe/${idParceiro}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_GET_PARTNER_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_GET_PARTNER_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DETAIL_REQUEST]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_TRAVEL_DETAIL_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/detalhe/${viagemId}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_TRAVEL_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_TRAVEL_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_GET_COLUMNS]: ({ commit }: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/colunas`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_PUT_COLUMNS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/transportadora/jbsviagem/colunas/salvar`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_DOWNLOAD_FILE]: ({ commit }: any, requestData: any) => {
		const { idViagem, tipoArquivo, nome } = requestData;
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/download/${tipoArquivo}/${idViagem}`,
				responseType: 'blob',
			})
				.then(({ data }) => {
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${nome}.pdf`);
					document.body.appendChild(link);
					link.click();
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_DOWNLOAD_IMAGE]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			const { formId, formType, ImageType, name } = params;
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/rastreamento/download/${formId}/${formType}/${ImageType}`,
				responseType: 'blob',
			})
				.then(({ data }) => {
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${name}.png`);
					document.body.appendChild(link);
					link.click();
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_ACTIVITY_HISTORY]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/historico-atividade/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_RESUME]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_TRAVEL_DETAIL_REQUEST);
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/resumo-detalhe/${idViagem}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_TRAVEL_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_TRAVEL_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_GET_KM_OCCURRENCE]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/ocorrencia/quilometragem/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_ANIMAL_OCCURRENCE]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/ocorrencia/animal/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_TRUCK_OCCURRENCE]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/ocorrencia/caminhao/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_TRUCK_OCCURRENCE]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/ocorrencia/caminhao/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_MAP_PATH]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/trajeto/mapa/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_GET_ACTUAL_LOCALIZATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idViagem, placaCavalo, motoristaId, pecuaristaOrigemId } = requestData;
			http({
				method: 'get',
				url: `/transportadora/jbsviagem/mapa/localizacao-atual?idViagem=${idViagem}&placaCavalo=${placaCavalo}&motoristaId=${motoristaId}&pecuaristaOrigemId=${pecuaristaOrigemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_PUT_TRAVEL_DETAIL]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/transportadora/jbsviagem/detalhe`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_UPLOAD_FILE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idViagem, formData } = requestData;
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/upload/${idViagem}`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_PROCESS_CTE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const chaveAcesso = requestData;
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/processamento-cte`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_ACTIVITY_HISTORY]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/historico-atividade`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_KM_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/ocorrencia/quilometragem/tratativa`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/ocorrencia/animal/embarque/tratativa`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/ocorrencia/animal/transporte/tratativa`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_ANIMAL_LANDING_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/ocorrencia/animal/desembarque/tratativa`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_TRUCK_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/transportadora/jbsviagem/ocorrencia/caminhao/tratativa`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[SELECT_TRANSIT_TRAVEL_DETAIL]: ({ commit }: any, travel) => {
		commit(SELECT_TRANSIT_TRAVEL_DETAIL, travel);
	},
	[SELECT_TRANSIT_MAP]: ({ commit }: any, map) => {
		commit(SELECT_TRANSIT_MAP, map);
	},
	[SELECT_TRANSIT_OCCURRENCE]: ({ commit }: any, occurrence) => {
		commit(SELECT_TRANSIT_OCCURRENCE, occurrence);
	},
	[SELECT_TRANSIT_RESUME]: ({ commit }: any, resume) => {
		commit(SELECT_TRANSIT_RESUME, resume);
	},
	[SELECT_TRANSIT_HISTORICAL]: ({ commit }: any, historic) => {
		commit(SELECT_TRANSIT_HISTORICAL, historic);
	},
	[SELECT_TRANSIT_TRAVEL_DETACHED_FORM]: ({ commit }: any, travel) => {
		commit(SELECT_TRANSIT_TRAVEL_DETACHED_FORM, travel);
	},
	[SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP]: ({ commit }: any, travel) => {
		commit(SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP, travel);
	},
	[SELECT_TRANSIT_DRIVER_DETACHED_FORM]: ({ commit }: any, driver) => {
		commit(SELECT_TRANSIT_DRIVER_DETACHED_FORM, driver);
	},
	[HANDLE_OUTSYSTEM_STATUS]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			http.defaults.headers.common['X-ApiKey'] = CONSTANTS.X_API_KEY;

			http({
				method: 'put',
				url: `/uboi-outsystem/flag-integracao/${idViagem}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DETACHED_DETAIL]: async ({ commit }: any, id: string) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/Forms/viagem-avulsa/${id}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
